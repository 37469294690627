<template>
    <v-container>
        <div class="d-flex">
            <v-btn
                icon
                color="accent darken-3"
                class="align-self-center"
                link
                :to="prevRoutePath"
            >
                <v-icon left> fa-solid fa-chevron-left </v-icon>
            </v-btn>
            <h2 class="primary--text main-heading">
                agROBOfood Associated Membership Terms
            </h2>
        </div>
        <v-divider class="mx-4" inset vertical> </v-divider>
        <v-spacer></v-spacer>

        <base-card>
            <template #content>
                <v-card-text>
                    <h2 class="text-h5 font-weight-bold">
                        Membership and Application
                    </h2>
                    <ul>
                        <li>
                            An associated membership is on a voluntary basis and
                            applicable for Digital Innovation Hubs (DIH) and
                            Competence Centers (CC) being active in delivering
                            services on a non-profit basis within the Agro-Food
                            and Robotics domain.
                        </li>

                        <li>
                            Associated members capable of delivering at least
                            one Eco-System or Business service are referred to
                            as a DIH.
                        </li>
                        <li>
                            Associated members capable of delivering at least
                            one Technology service are referred to as a CC.
                        </li>
                        <li>
                            Joining the agROBOfood network is free of charge at
                            least until June 1st, 2022. A membership fee might
                            be applicable from that moment on depending on the
                            long-term sustainability strategy to be agreed by
                            the project partners.
                        </li>
                        <li>
                            Applications to become an associated member can be
                            send through the agROBOfood website
                            (www.agrobofood.eu).
                        </li>
                        <li>
                            Applicants are required to sign the application form
                            and state that they will respect the general GDPR
                            policies.
                        </li>
                        <li>
                            Applicants will receive a notification from the
                            administrator via e-mail whether they are eligible
                            to become an associated member and to which
                            agROBOfood Regional Cluster they belong. Upon
                            confirmation by the applicant via an e-mail to the
                            administrator, the new associated membership will
                            start.
                        </li>
                        <li>
                            New associated members will be appointed a contact
                            person, being either the Regional Cluster Leader or
                            the local DIH-node (orchestrator).
                        </li>
                        <li>
                            Associated members have the right to step out of the
                            network as a member and to be deleted from the
                            e-mail lists and newsletters. To withdraw,
                            associated members must send a request to the
                            administrator (coordinator) for that.
                        </li>
                        <li>
                            In case associated members do not respond for a
                            period of more than one year to general
                            communications or are not active in the network,
                            after prior notice, the agROBOfood network may
                            delete the associated member from the DIH-catalogue,
                            the website and from the e-mail lists and discard
                            access to the agROBOfood portal.
                        </li>
                    </ul>

                    <h2 class="text-h5 font-weight-bold">
                        Access to the agROBOfood network and information
                    </h2>
                    <ul>
                        <li>
                            Associated members will be entitled to use the
                            agROBOfood logo for communication purposes. Each
                            Regional Cluster will use a specific colored version
                            of that logo being available from the website.
                        </li>
                        <li>
                            Associated members will automatically receive the
                            agROBOfood newsletter.
                        </li>
                        <li>
                            Associated members will have access to a password
                            protected area on the agROBOfood portal currently
                            being a Basecamp folder.
                        </li>
                        <li>
                            Associated members will have access to a password
                            protected area on the agROBOfood portal currently
                            being a Basecamp folder.
                        </li>
                        <li>
                            Associated members will have access to their
                            specific Regional Cluster folder on the agROBOfood
                            portal.
                        </li>
                        <li>
                            Associated members will have access to all contacts
                            within the agROBOfood network, made available as
                            e-mail lists.
                        </li>
                        <li>
                            Associated members will have right to join general
                            agROBOfood meetings and joined activities for free
                            (no entrance fee).
                        </li>
                        <li>
                            Associated members, not being an agROBOfood
                            consortium project partner, may make use of the open
                            calls.
                        </li>
                        <li>
                            Registered associated members will not receive any
                            financial compensation from the agROBOfood project.
                            Costs incurred by associated members for agROBOfood
                            network activities must be recovered from associated
                            members’ own funds (from own budget, clients,
                            subsidies etc). Within the agROBOfood project there
                            is a limited budget to pay for attending meetings,
                            but this budget will be supplied on decisions taken
                            by the agROBOfood project consortium partners.
                        </li>
                        <li>
                            Associated members will be registered in the
                            DIH-catalogue. Detailed service activities of the
                            DIH/CC will be registered and made available to all
                            associated members.
                        </li>
                        <li>
                            Associated members will be registered in the
                            agROBOfood website database, which will be made
                            searchable (services, countries, technologies, key
                            words, etc.).
                        </li>
                        <li>
                            Associated members are entitled to receive and
                            deliver network services, defined as services from
                            DIH to DIH (by RODIN) and referred to within
                            agROBOfood (WP6) as “tools” or advanced network
                            services.
                        </li>
                        <li>
                            Associated members are offered a free and
                            non-mandatory maturity test to help them grow their
                            maturity as a DIH/CC. The test is based on a
                            self-declaration. It will give the maturity of the
                            DIH/CC in term of a specific ranking for different
                            categories and will additionally give advice on
                            which tools to use to enhance the maturity. The
                            result of the maturity test is confidential
                            (non-public).
                        </li>
                        <li>
                            Associated members may promote its maturity through
                            posting Show Cases on the agROBOfood website.
                        </li>
                    </ul>

                    <h2 class="text-h5 font-weight-bold">Obligations:</h2>
                    <ul>
                        <li>
                            Associated members must co-operate in informing the
                            agROBOfood coordinator at least once a year about
                            their progress, performance and the activities done
                            on behalf of the agROBOfood network. Quantification
                            of data is required (f.i. number of services
                            delivered, number of cross- border contacts). This
                            performance check will be done in the form of an
                            on-line questionnaire.
                        </li>
                        <li>
                            Associated members must collaborate in data
                            gathering processes initiated by the agROBOfood
                            project via their contact person.
                        </li>
                        <li>
                            The member must collaborate in disseminating
                            agROBOfood content throughout its own networks
                            (clients newsletters etc.).
                        </li>
                    </ul>
                </v-card-text>
            </template>
        </base-card>
    </v-container>
</template>

<script>
import BaseCard from "@/components/base/BaseCard.vue";
export default {
    name: "DihTerms",

    data() {
        return {
            prevRoute: null,
        };
    },
    computed: {
        prevRoutePath() {
            return this.prevRoute ? this.prevRoute.path : "/";
        },
    },

    beforeRouteEnter(_, from, next) {
        next((vm) => {
            vm.prevRoute = from;
        });
    },
};
</script>

<style scoped>
h2 {
    padding: 15px 0;
}

li {
    font-size: 0.9rem;
    padding: 4px 0;
}
</style>
